import { memo } from 'react';

import { StackProps } from '@tamagui/core';

import { Skeleton } from '../../atoms';

import { TableRow } from './TableRow';

export interface TableLoadingStateProps {
  rows: number;
  rowHeight?: StackProps['height'];
}

/**
 * Skeleton loading state for table rows.
 *
 * Creates N rows at 100% width with the provided height (default is `$12`).
 */
export const TableLoadingState = memo(({ rows, rowHeight = '$12' }: TableLoadingStateProps) => {
  return (
    <>
      {Array.from(Array(rows)).map((_, placeholderIdx: number) => (
        <TableRow px="$6" height={rowHeight} key={`row-placeholder-${placeholderIdx}`}>
          <Skeleton.Rect w="100%" h="$4" />
        </TableRow>
      ))}
    </>
  );
});
