import { GetProps, styled } from '@tamagui/core';

import { Stack } from '../../atoms';

const TABLE_ROW_NAME = 'TableRow';

const TableRowFrame = styled(Stack, {
  name: TABLE_ROW_NAME,
  row: true,
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  role: 'row',

  variants: {
    clickable: {
      true: {
        hoverStyle: {
          backgroundColor: '$interactive.neutral.hoveredInverted',
        },
        focusStyle: {
          backgroundColor: '$interactive.neutral.hoveredInverted',
        },
        cursor: 'pointer',
      },
    },
    variant: {
      header: {
        backgroundColor: '$onSurface.neutral.zebra',
        borderTopLeftRadius: '$2',
        borderTopRightRadius: '$2',
        /**
         * Note: since Tamagui doesn't recognize 'sticky' as a valid option, this is the sanest way
         * to resolve the types. This component is web-only, so no issues for now with native UX.
         */
        position: 'sticky' as 'absolute',
        zIndex: 20,
        top: 0,
      },
      footer: {
        py: '$4',
        backgroundColor: '$onSurface.neutral.zebraAlt',
        borderBottomLeftRadius: '$2',
        borderBottomRightRadius: '$2',
        borderBottomWidth: 0,
      },
    },
  } as const,
});

export type TableRowProps = GetProps<typeof TableRowFrame>;

export const TableRow = TableRowFrame;
