import { createStyledContext } from '@tamagui/core';

const TableVariants = {
  full: 'full',
  minimal: 'minimal',
} as const;

type TableVariants = (typeof TableVariants)[keyof typeof TableVariants];

export const TableStyledContext = createStyledContext({
  variant: 'full' as TableVariants,
});
