import { Ref, useCallback, useMemo, useState } from 'react';
import { LayoutChangeEvent } from 'react-native';

import { runOnJS, useAnimatedReaction, useSharedValue } from 'react-native-reanimated';

import { GetProps, StackProps, TamaguiElement, isWeb, styled, useProps } from '@tamagui/core';

import { Stack } from '../../atoms';

import { TableStyledContext } from './TableStyledContext';

const TABLE_BODY_NAME = 'TableBody';

export interface TableBodyFrameExtraProps {
  tableBodyScrollRef: Ref<TamaguiElement>;
  height: StackProps['height'];
  pinnedColumnsMinWidth?: number;
  rowMinWidth?: number;
}

const MaskImageNone = 'none' as const;
const MaskImageFadeRight = `
  linear-gradient(
    to right,
    white calc(100% - 16px),
    transparent
  )
` as const;
const MaskImageFadeLeft = `
  linear-gradient(
    to left,
    white calc(100% - 16px),
    transparent
  )
` as const;

const MaskPositionDefault = `0,0`;

/**
 * To support mobile devices, we'll need to offload the virtualization task to FlashList, or even
 * the built-in FlatList component for more targeted performance gains.
 *
 * We won't need the tableBodyScrollRef, but ref's by default being undefined we should be OK using
 * the existing interface.
 */
const TableBodyFrame = styled(Stack, {
  name: TABLE_BODY_NAME,

  borderBottomLeftRadius: '$2',
  borderBottomRightRadius: '$2',
  backgroundColor: '$foundation.neutral.yang',
  context: TableStyledContext,
  role: 'rowgroup',

  variants: {
    variant: {
      minimal: {
        borderTopLeftRadius: '$2',
        borderTopRightRadius: '$2',
      },
      full: {},
    },
  } as const,
});

type TableBodyProps = TableBodyFrameExtraProps & GetProps<typeof TableBodyFrame>;

export const TableBody = TableBodyFrame.styleable<TableBodyProps>((propsIn, ref) => {
  const { tableBodyScrollRef, height, pinnedColumnsMinWidth, rowMinWidth, children, ...rest } = useProps(propsIn);
  const [maskImage, setMaskImage] = useState<string>(MaskImageNone);
  const [maskPosition, setMaskPosition] = useState<string>(MaskPositionDefault);

  // Moderately-random ID to avoid conflicting table body scroll listeners on the same page
  const tableBodyId = useMemo(() => `table-body-id-${Math.floor(Math.random() * 100)}`, []);

  const isAtLeft = useSharedValue(1);
  const isAtRight = useSharedValue(0);

  const setMaskGradient = useCallback(
    (atLeftEdge: boolean, atRightEdge: boolean) => {
      // Assume we're somewhere in the middle...
      let newMaskImage = `${MaskImageFadeLeft},${MaskImageFadeRight};`;
      let newMaskPosition = `${pinnedColumnsMinWidth ?? 0}px,0px`;

      // ...unless we're at an edge and need to adjust
      if (atLeftEdge && atRightEdge) {
        newMaskImage = MaskImageNone;
        newMaskPosition = MaskPositionDefault;
      } else if (atLeftEdge) {
        newMaskImage = `${MaskImageFadeRight};`;
        newMaskPosition = MaskPositionDefault;
      } else if (atRightEdge) {
        newMaskImage = `${MaskImageFadeLeft};`;
      }

      setMaskImage(newMaskImage);
      setMaskPosition(newMaskPosition);
    },
    [pinnedColumnsMinWidth],
  );

  /**
   * Update the left/right edge ref values based on scroll events
   */
  const scrollHandler = ({ target }: Event) => {
    // @ts-ignore event type woes... doesn't think these are valid keys
    isAtLeft.value = target?.scrollLeft === 0 ? 1 : 0;
    // @ts-ignore event type woes... doesn't think these are valid keys
    isAtRight.value = target?.offsetWidth + target?.scrollLeft >= target?.scrollWidth ? 1 : 0;
  };

  /**
   * This doesn't animate it, and we should figure out how to do that.
   */
  useAnimatedReaction(
    () => ({ atLeftEdge: isAtLeft.value === 1, atRightEdge: isAtRight.value === 1 }),
    (atEdges, previousAtEdges) => {
      if (atEdges.atLeftEdge === previousAtEdges?.atLeftEdge && atEdges.atRightEdge === previousAtEdges?.atRightEdge) {
        return;
      }

      runOnJS(setMaskGradient)(atEdges.atLeftEdge, atEdges.atRightEdge);
    },
    [isAtLeft, isAtRight, pinnedColumnsMinWidth],
  );

  /**
   * Handle setting the initial state onLayout, since we won't have any scroll events to trigger
   * the other calculation.
   */
  const initScrollMasksOnLayout = useCallback(
    (event: LayoutChangeEvent) => {
      // We can assume we're at the left edge to begin; but does the entire row fit inside the current layout?
      if (rowMinWidth && rowMinWidth <= event.nativeEvent.layout.width) {
        isAtRight.value = 1;
      }

      // Attach scroll handler via JS
      try {
        const tableBodyComponent = document?.querySelector(`#${tableBodyId}`);

        if (tableBodyComponent) {
          tableBodyComponent.addEventListener('scroll', scrollHandler);
        }
      } catch (err) {
        console.log('error attaching scroll handler to table body:', err);
      }
    },
    [rowMinWidth],
  );

  return (
    <TableBodyFrame
      {...rest}
      ref={ref}
      style={{
        WebkitMaskImage: maskImage,
        WebkitMaskPosition: maskPosition,
        WebkitMaskComposite: 'source-in', // chrome
        maskComposite: 'intersect', // firefox
      }}
    >
      <Stack
        id={tableBodyId}
        ref={tableBodyScrollRef}
        onLayout={initScrollMasksOnLayout}
        overflow={isWeb ? ('auto' as 'scroll') : 'scroll'}
        height={height}
      >
        {children}
      </Stack>
    </TableBodyFrame>
  );
});
