import { useCallback, useMemo } from 'react';

import { Row, TableState } from '@tanstack/react-table';

/**
 * Column pinning!
 *
 * We only support left-pinning, and this is a bit more manual than I'd like, but TLDR:
 * - we get the pinning state from the Table
 * - we want to know how 'wide' the pinned area is so we can calculate the location of the horizontal scroll shadow inset
 * - most of this data gets piped into the TableBody component
 */
export const useColumnPinning = <TData>(rawTableRows: Row<TData>[], tableState: TableState) => {
  const cellsInFirstRow = rawTableRows[0]?.getVisibleCells() ?? [];
  const getMinWidthOfColumns = useCallback(
    (columnIds: string[]) => {
      return columnIds.reduce((acc: number, columnId: string) => {
        const cell = cellsInFirstRow.find((cell) => cell.column.id === columnId);
        if (cell?.column) {
          return acc + cell.column.getSize();
        } else {
          return acc;
        }
      }, 0);
    },
    [cellsInFirstRow],
  );

  const lastPinnedColumnId = tableState.columnPinning.left?.slice(-1)[0];
  const pinnedColumnsMinWidth = useMemo(
    () => getMinWidthOfColumns(tableState.columnPinning.left ?? []),
    [cellsInFirstRow],
  );
  const rowMinWidth = useMemo(
    () => getMinWidthOfColumns(cellsInFirstRow.map((cell) => cell.column.id)),
    [cellsInFirstRow],
  );

  return {
    lastPinnedColumnId,
    pinnedColumnsMinWidth,
    rowMinWidth,
  };
};
