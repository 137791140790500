import { forwardRef, useMemo } from 'react';
import { GestureResponderEvent } from 'react-native';

import { GetProps, TamaguiElement, styled, useMedia, useProps } from '@tamagui/core';

import { Select, Stack, UtilityText, ValueText } from '../../atoms';
import { ArrowLeftIcon, ArrowRightIcon } from '../../icons';
import { Button } from '../button';

import { UsePaginationReturn } from './usePagination';

export const PAGINATION_CONTAINER_NAME = 'PaginationContainer';
const PAGINATION_LABEL_NAME = 'PaginationLabel';
const PAGINATION_VALUE_NAME = 'PaginationValue';

const PaginationContainer = styled(Stack, {
  name: PAGINATION_CONTAINER_NAME,

  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: '$4',
  py: '$4',

  bg: '$onSurface.neutral.zebraAlt',

  $gtSm: {
    px: '$6',
  },
});

const PaginationLabel = styled(UtilityText, {
  name: PAGINATION_LABEL_NAME,

  token: 'utility.helper.small',
  color: '$onSurface.neutral.muted',
});

const PaginationValue = styled(ValueText, {
  name: PAGINATION_VALUE_NAME,

  token: 'value.xsmall',
  color: '$onSurface.neutral.muted',
});

const PAGE_SIZE_OPTIONS = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
];

export interface PaginationExtraProps {
  /**
   * Can be used to hook into the events in which the next or previous page button is pressed.
   */
  onPagePressed?: (event: GestureResponderEvent) => void;
  /**
   * Typically comes from usePagination, but is an optional prop here for cases where the total
   * dataset is not known (e.g., cursor-based pagination).
   */
  page?: number;
  /**
   * Typically comes from usePagination, but is an optional prop here for cases where the total
   * dataset is not known (e.g., cursor-based pagination).
   */
  totalItems?: number;
}

export type PaginationProps = GetProps<typeof PaginationContainer> &
  Omit<UsePaginationReturn, 'setPage' | 'totalPages' | 'page' | 'totalItems'> &
  PaginationExtraProps;

const PaginationComponent = forwardRef<TamaguiElement, PaginationProps>((propsIn, ref) => {
  const {
    canNextPage,
    canPreviousPage,
    itemsPerPage,
    setItemsPerPage,
    page,
    totalItems,
    nextPage,
    onPagePressed,
    previousPage,
    ...rest
  } = useProps(propsIn);
  const media = useMedia();

  // TODO: intl-ize?
  const currentRangeLabel = useMemo(() => {
    if (itemsPerPage && page && totalItems) {
      return `${itemsPerPage! * (page! - 1) + 1}-${Math.min(itemsPerPage! * page!, totalItems!)} of ${totalItems} Items`;
    }
  }, [itemsPerPage, page, totalItems]);

  return (
    <Stack bg="$onSurface.neutral.zebraAlt">
      <PaginationContainer ref={ref} {...rest}>
        <Stack flexDirection="row" alignItems="center" gap="$2" flexGrow={1}>
          {/* TODO: intl-ize? */}
          <PaginationLabel>Items per page:</PaginationLabel>
          {setItemsPerPage ? (
            <Select
              aria-label="Select items per page"
              value={itemsPerPage}
              onChange={(newVal) => newVal && setItemsPerPage(newVal)}
              inputFrameProps={{
                variant: 'ghost',
                condensed: true,
              }}
            >
              {PAGE_SIZE_OPTIONS.map((pageSizeOption, index) => (
                <Select.Item
                  key={`page-size-option-${index}`}
                  value={pageSizeOption.value}
                  label={pageSizeOption.label}
                />
              ))}
            </Select>
          ) : (
            <PaginationValue>{itemsPerPage}</PaginationValue>
          )}
        </Stack>
        <Stack flexDirection="row" alignItems="center" gap="$4">
          {currentRangeLabel && (
            <PaginationLabel key={`pagination-label-${itemsPerPage}`}>{currentRangeLabel}</PaginationLabel>
          )}
          <Button
            // TODO: intl-ize
            aria-label="Previous Page"
            variant={media.gtSm ? 'ghost' : 'outlined'}
            condensed={true}
            onPress={(event) => {
              previousPage!();
              onPagePressed?.(event);
            }}
            disabled={!canPreviousPage!()}
            Icon={ArrowLeftIcon}
            iconPosition="before"
            $gtSm={{
              width: '$4',
              height: '$4',
            }}
          />
          <Button
            // TODO: intl-ize
            aria-label="Next Page"
            variant={media.gtSm ? 'ghost' : 'outlined'}
            condensed={true}
            onPress={(event) => {
              nextPage!();
              onPagePressed?.(event);
            }}
            disabled={!canNextPage!()}
            Icon={ArrowRightIcon}
            $gtSm={{
              width: '$4',
              height: '$4',
            }}
          />
        </Stack>
      </PaginationContainer>
    </Stack>
  );
});

export const Pagination = PaginationContainer.styleable<PaginationProps>(PaginationComponent);
