import { useEffect, useMemo } from 'react';

import { GetProps, Stack, createStyledContext, styled, useProps, withStaticProperties } from '@tamagui/core';

import { SortDirection } from '@tanstack/react-table';

import { BodyText, UtilityText, ValueText } from '../../atoms';
import { SortAscendingIcon, SortDescendingIcon } from '../../icons';

const TABLE_CELL_NAME = 'TableCell';
const TABLE_CELL_HEADER_NAME = 'TableCellHeader';
const TABLE_CELL_HEADER_TEXT_NAME = 'TableCellHeaderText';
const TABLE_CELL_GROUP_LABEL_TEXT_NAME = 'TableCellGroupLabelText';
const TABLE_CELL_TEXT_NAME = 'TableCellText';
const TABLE_CELL_VALUE_NAME = 'TableCellValue';
const TABLE_CELL_HELPER_NAME = 'TableCellHelper';

const TableColumnStyledContext = createStyledContext({
  isNumericalData: false,
  isPinnedColumn: false,
  isFirstColumn: false,
  isLastColumn: false,
});

interface TableCellFrameExtraProps {
  isPinnedColumn?: boolean;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
}

const TableCellFrame = styled(Stack, {
  name: TABLE_CELL_NAME,
  justifyContent: 'center',
  alignItems: 'flex-start',
  height: '100%',
  flexGrow: 1,
  py: '$3',
  px: '$2',
  borderBottomWidth: '$0.25',
  borderColor: '$onSurface.neutral.outlineAlt',
  backgroundColor: '$foundation.neutral.yang',
  context: TableColumnStyledContext,
  // @ts-ignore-next-line: this is a valid role, but Tamagui doesn't have it in its types
  role: 'gridcell',

  variants: {
    isNumericalData: {
      true: {
        alignItems: 'flex-end',
      },
    },
    isPinnedColumn: {
      true: {
        /**
         * Note: since Tamagui doesn't recognize 'sticky' as a valid option, this is the sanest way
         * to resolve the types. This component is web-only, so no issues for now with native UX.
         */
        position: 'sticky' as 'absolute',
        top: 0,
        zIndex: 10,
        backgroundColor: '$foundation.neutral.yang',
      },
    },
    isFirstColumn: {
      true: {
        pl: '$6',
      },
    },
    isLastColumn: {
      true: {
        pr: '$6',
      },
    },
    hideBottomBorder: {
      true: {
        borderBottomWidth: 0,
      },
    },
  } as const,
});

export interface TableCellHeaderExtraProps {
  /** Whether this column has sorting enabled. */
  sortEnabled?: boolean;
  /** Sort direction. Either `asc`, `desc`, or false. */
  sortDirection?: SortDirection | false;
}

const TableCellHeader = styled(Stack, {
  name: TABLE_CELL_HEADER_NAME,
  height: '$4',
  flexGrow: 1,
  py: '$6',
  px: '$2',
  gap: '$1',
  backgroundColor: '$onSurface.neutral.zebra',
  borderBottomWidth: '$0.25',
  borderColor: '$onSurface.neutral.outlineAlt',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'row',
  role: 'columnheader',

  context: TableColumnStyledContext,

  variants: {
    sortEnabled: {
      true: {
        cursor: 'pointer',
      },
    },
    isNumericalData: {
      true: {
        justifyContent: 'flex-end',
      },
    },
    isPinnedColumn: {
      true: {
        /**
         * Note: since Tamagui doesn't recognize 'sticky' as a valid option, this is the sanest way
         * to resolve the types. This component is web-only, so no issues for now with native UX.
         */
        position: 'sticky' as 'absolute',
        top: 0,
        zIndex: 20,
        backgroundColor: '$onSurface.neutral.zebra',
      },
    },
    isFirstColumn: {
      true: {
        pl: '$6',
      },
    },
    isLastColumn: {
      true: {
        pr: '$6',
      },
    },
  } as const,
});

const TableCellHeaderText = styled(UtilityText, {
  name: TABLE_CELL_HEADER_TEXT_NAME,
  token: 'utility.label.xxsmall',
  textTransform: 'uppercase',
  color: '$onSurface.neutral.muted',

  context: TableColumnStyledContext,

  variants: {
    isActive: {
      true: {
        color: '$interactive.primary.rested',
      },
    },

    isNumericalData: {
      true: {
        textAlign: 'right',
      },
    },
  } as const,
});

const TableCellGroupLabelText = styled(UtilityText, {
  name: TABLE_CELL_GROUP_LABEL_TEXT_NAME,
  token: 'utility.label.small.alt',
  color: '$onSurface.neutral.muted',
});

const TableCellText = styled(BodyText, {
  name: TABLE_CELL_TEXT_NAME,
  token: 'body.compact.small',
  color: '$onSurface.neutral.muted',
});

const TableCellValue = styled(ValueText, {
  name: TABLE_CELL_VALUE_NAME,
  token: 'value.small',
});

const TableCellHelper = styled(ValueText, {
  name: TABLE_CELL_HELPER_NAME,
  token: 'value.xsmall.alt',
  color: '$onSurface.neutral.muted',
});

export type TableCellProps = GetProps<typeof TableCellFrame & TableCellFrameExtraProps>;

export const TableCell = withStaticProperties(
  TableCellFrame.styleable<TableCellProps>((propsIn, ref) => {
    useEffect(() => {
      // Get spans in div role grid and not already have role presentation
      if (document) {
        const span = document.querySelector('div[role="grid"] ._dsp_contents:not([role="presentation"])');

        if (span) {
          // add presentation role to span
          span.setAttribute('role', 'presentation');
        }
      }
    }, []);

    return <TableCellFrame {...propsIn} ref={ref} />;
  }),
  {
    Header: TableCellHeader.styleable<TableCellProps & TableCellHeaderExtraProps>((propsIn, ref) => {
      const { children, sortEnabled, sortDirection, ...rest } = useProps(propsIn);
      const isActive = Boolean(sortDirection) !== false;

      const isRightAligned = rest.isNumericalData;
      const sortBody = useMemo(() => {
        if (Boolean(sortEnabled) && isActive) {
          if (sortDirection === 'asc') {
            return (
              <SortAscendingIcon
                size="sm"
                color={isActive ? '$interactive.primary.rested' : '$onSurface.neutral.muted'}
              />
            );
          }

          if (sortDirection === 'desc') {
            return (
              <SortDescendingIcon
                size="sm"
                color={isActive ? '$interactive.primary.rested' : '$onSurface.neutral.muted'}
              />
            );
          }
        }

        return null;
      }, [sortDirection, sortEnabled, isActive]);

      return (
        <TableCellHeader ref={ref} sortEnabled={sortEnabled} {...rest}>
          {isRightAligned && sortBody}
          <TableCellHeaderText isActive={isActive}>{children}</TableCellHeaderText>
          {!isRightAligned && sortBody}
        </TableCellHeader>
      );
    }),
    GroupLabel: TableCellGroupLabelText,
    Text: TableCellText,
    Value: TableCellValue,
    Helper: TableCellHelper,
  },
);
