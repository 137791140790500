import { Footer, Header, Press, TestimonialVideoCarousel } from '../sections';

import { PageProps } from './PageProps';
import { useEmailOnSubmit } from './useEmailOnSubmit';

export const Page1 = ({ handleSignup }: PageProps) => {
  const onSubmit = useEmailOnSubmit(handleSignup);

  return (
    <>
      <Header $gtMd={{ orientation: 'row' }}>
        <Header.Main containerProps={{ alignSelf: 'center', maxWidth: 750 }}>
          <Header.Main.Title>Easily invest in rental homes and vacation rentals</Header.Main.Title>
          <Header.Main.SubTitle>
            Grow your real estate portfolio while earning rental income & appreciation by investing as little as $100.
          </Header.Main.SubTitle>
          <Header.Main.Signup onSubmit={onSubmit} gap="$3" maxWidth={650} alignSelf="center" width="100%" />
        </Header.Main>
        <Header.Secondary h={280} $gtXs={{ h: 400 }} $gtMd={{ h: 'auto' }}>
          <Header.Secondary.VacationRental
            condensed
            $gtMd={{ condensed: false }}
            vacationRentals={[
              {
                rating: 4.96,
                name: 'The Suitespot',
                image: '/features/landing-pages/vacation-rentals/the-suitespot.png',
              },
              { rating: 4.82, name: 'The Cactus', image: '/features/landing-pages/vacation-rentals/the-cactus.png' },
              { rating: 5.0, name: 'The Koi', image: '/features/landing-pages/vacation-rentals/the-koi.png' },
              { rating: 4.89, name: 'The Smokey', image: '/features/landing-pages/vacation-rentals/the-smokey.png' },
            ]}
          />
        </Header.Secondary>
      </Header>
      <Press width="100%" variant="carousel" showingCount={6} maxWidth={1024} alignSelf="center">
        <Press.Article
          image="https://cdn.arrivedhomes.com/features/landing-pages/press/yahoo.png"
          content="Jeff Bezos-Backed Real Estate Startup Surpasses $65 Million In Funded Property Value"
        />
        <Press.Article
          image="https://cdn.arrivedhomes.com/features/landing-pages/press/bloomberg.png"
          content="Jeff Bezos and Marc Benioff are backing a startup that lets regular people invest small amounts of money in single-family rental properties"
        />
        <Press.Article
          image="https://cdn.arrivedhomes.com/features/landing-pages/press/businessinsider.png"
          content="Jeff Bezos-backed real estate investment company breaks down how retail investors can acquire single-family rentals with as little as $100"
        />
        <Press.Article
          image="https://cdn.arrivedhomes.com/features/landing-pages/press/techcrunch.png"
          content="Bezos backs Arrived, a startup that lets you buy into single-family rentals for 'as little as $100'"
        />
        <Press.Article
          image="https://cdn.arrivedhomes.com/features/landing-pages/press/wsj.png"
          content="Arrived, signed up 12,000 people to invest in 150 rental homes in the past year, with more than 100,000 others applying to make future investments through the company"
        />
        <Press.Article
          image="https://cdn.arrivedhomes.com/features/landing-pages/press/geekwire.png"
          content="Rental property investment startup Arrived Homes raises $25M to fund further expansion"
        />
      </Press>
      <TestimonialVideoCarousel
        alignSelf="center"
        testimonials={[
          {
            since: 'December 2021',
            quote:
              "I invest in Arrived because it's got an exceptional leadership team that has run successful businesses before, and I think when you're looking for an early stage startup, you want to really have confidence in the team.",
            name: 'Scott',
            youtubeId: 'nbnIxz95qgc',
          },
        ]}
        maxWidth={1280}
      />
      <Footer w="100%" $gtSm={{ variant: 'stacked', backdrop: 'coins' }}>
        <Footer.Background />
        <Footer.Text>Buy shares of rental properties, let Arrived take care of the rest</Footer.Text>
        <Footer.Signup onSubmit={onSubmit} />
      </Footer>
    </>
  );
};
