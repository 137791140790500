import { FC, forwardRef, useCallback, useMemo, useRef } from 'react';

import { GetProps, Stack, StackProps, TamaguiElement, styled, withStaticProperties } from '@tamagui/core';

import { Row, Table as TanTable, flexRender } from '@tanstack/react-table';
import { VirtualizerOptions, useVirtualizer } from '@tanstack/react-virtual';

import { PAGINATION_CONTAINER_NAME, Pagination } from '../../molecules';
import { parseRootChildren } from '../../utils';

import { TableBody } from './TableBody';
import { TableCell } from './TableCell';
import { TableHeader } from './TableHeader';
import { TableLoadingState } from './TableLoadingState';
import { TableRow, TableRowProps } from './TableRow';
import { TableStyledContext } from './TableStyledContext';
import { useColumnPinning } from './utils';

const TABLE_NAME = 'Table';
interface TableFrameExtraProps<TData> {
  table: TanTable<TData>;
  /**
   * Applies to the height of the content, excluding the optional header/footer controlled by the `variant` prop.
   *
   * Enables the virtualizer to work properly. Defaults to 500(px).
   */
  height?: StackProps['height'];
  /**
   * Applies to the loading state skeleton and the virtualizer config. Defaults to 60(px).
   */
  estimatedRowHeight?: StackProps['height'];
  initialPageSize?: number;
  onRowClick?: (row: Row<TData>) => void;
  virtualOptions?: Partial<VirtualizerOptions<HTMLDivElement, HTMLDivElement>>;
  isLoading?: boolean;
  EmptyStateComponent?: FC;
  className?: string;
}

const TableFrame = styled(Stack, {
  name: TABLE_NAME,
  flex: 1,
  width: '100%',
  borderRadius: '$2',
  borderWidth: '$0.25',
  borderColor: '$onSurface.neutral.outlineAlt',
  backgroundColor: '$onSurface.neutral.zebraAlt',
  overflow: 'hidden',
  role: 'grid',
  tabIndex: 0,

  context: TableStyledContext,

  variants: {
    variant: {
      full: {},
      minimal: {},
    },
  } as const,

  defaultVariants: {
    variant: 'full',
  },
});

export type TableProps<D> = GetProps<typeof TableFrame> & TableFrameExtraProps<D>;
/**
 * DESKTOP ONLY: Container for the body/rows of the table. Height is required for scrolling to work.
 */
export const Table = withStaticProperties(
  // @ts-ignore someday I'll fix this generic :,)
  forwardRef<TamaguiElement, TableProps<D>>(
    <D,>(
      {
        // @ts-ignore
        table,
        // @ts-ignore
        variant,
        // @ts-ignore
        height,
        // @ts-ignore
        estimatedRowHeight = 60,
        // @ts-ignore
        isLoading,
        // @ts-ignore
        EmptyStateComponent,
        // @ts-ignore
        children: childrenIn,
        ...rest
      },
      // @ts-ignore
      ref,
    ) => {
      const rawTableRows = table.getRowModel().rows;
      const hasDataToRender = rawTableRows.length !== 0;
      const tableBodyScrollRef = useRef<TamaguiElement>(null);

      const { lastPinnedColumnId, pinnedColumnsMinWidth, rowMinWidth } = useColumnPinning(
        rawTableRows,
        table.getState(),
      );

      const reactVirtualizer = useVirtualizer({
        count: rawTableRows.length,
        getScrollElement: () => tableBodyScrollRef.current,
        estimateSize: () => estimatedRowHeight,
        overscan: 10,
        ...rest.virtualOptions,
      });

      const Row = useCallback(
        ({
          row,
          index,
          isLastItemOnPage,
          rowProps,
        }: {
          row: Row<D>;
          index: number;
          isLastItemOnPage: boolean;
          rowProps?: TableRowProps;
        }) => {
          const cellsToRender = row.getVisibleCells();

          return (
            <TableRow key={`row-${index}`} clickable={Boolean(rest?.onRowClick)} {...rowProps}>
              {cellsToRender.map((cell, cellIdx) => {
                const isPinnedColumn = cell.column.getIsPinned() === 'left';
                const hideBottomBorder = variant === 'minimal' && isLastItemOnPage;

                return (
                  <TableCell
                    key={`row-${index}-cell-${cellIdx}`}
                    width={cell.column.getSize()}
                    left={isPinnedColumn ? cell.column.getStart() : 0}
                    onPress={() => rest?.onRowClick && rest.onRowClick(row)}
                    isFirstColumn={cellIdx === 0}
                    isLastColumn={cellIdx + 1 === cellsToRender.length}
                    isPinnedColumn={isPinnedColumn}
                    hideBottomBorder={hideBottomBorder}
                    aria-colindex={cellIdx + 1}
                    {...cell.column.columnDef.meta}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        },
        [rest?.onRowClick],
      );

      const { [PAGINATION_CONTAINER_NAME]: CustomPagination, children: _ } = useMemo(
        () => parseRootChildren(childrenIn, [PAGINATION_CONTAINER_NAME]),
        [childrenIn],
      );

      return (
        <TableFrame
          ref={ref}
          aria-rowcount={rawTableRows.length}
          aria-colcount={table.getAllFlatColumns().length}
          aria-busy={isLoading}
          className={rest?.className}
        >
          <TableBody
            variant={variant}
            height={height}
            minHeight={rest?.minHeight || estimatedRowHeight}
            maxHeight={rest?.maxHeight}
            pinnedColumnsMinWidth={pinnedColumnsMinWidth}
            rowMinWidth={rowMinWidth}
            tableBodyScrollRef={tableBodyScrollRef}
          >
            {variant !== 'minimal' && (
              <TableHeader
                sortEnabled={!isLoading && hasDataToRender}
                lastPinnedColumnId={lastPinnedColumnId}
                headerGroups={table.getHeaderGroups()}
                aria-rowindex={1}
              />
            )}
            {/* Table content wrapper needs to either contain all virtual items, or be set to (100% - header height) */}
            <Stack height={reactVirtualizer.getTotalSize() || '100%'} width="100%" position="relative">
              {isLoading && (
                <TableLoadingState rows={table.getState().pagination.pageSize} rowHeight={estimatedRowHeight} />
              )}
              {!isLoading && !hasDataToRender && EmptyStateComponent && (
                <Stack width="100%" height="100%" flex={1} alignItems="center" justifyContent="center">
                  <EmptyStateComponent />
                </Stack>
              )}
              {!isLoading &&
                hasDataToRender &&
                reactVirtualizer.getVirtualItems().map((virtualRow) => {
                  return (
                    <Row
                      key={virtualRow.key}
                      index={virtualRow.index}
                      isLastItemOnPage={virtualRow.index + 1 === reactVirtualizer.getVirtualItems().length}
                      aria-rowindex={variant !== 'minimal' ? virtualRow.index + 2 : virtualRow.index + 1}
                      row={rawTableRows[virtualRow.index] as Row<D>}
                      rowProps={{
                        height: virtualRow.size,
                        width: '100%',
                        // Positioning based on https://tanstack.com/virtual/v3/docs/guide/introduction
                        transform: [{ translateY: virtualRow.start }],
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}
                    />
                  );
                })}
            </Stack>
          </TableBody>
          {CustomPagination}
          {variant !== 'minimal' && !CustomPagination && (
            <Pagination
              bg="$onSurface.neutral.zebraAlt"
              canNextPage={table.getCanNextPage}
              canPreviousPage={table.getCanPreviousPage}
              itemsPerPage={table.getState().pagination.pageSize}
              setItemsPerPage={table.setPageSize}
              nextPage={table.nextPage}
              page={table.getState().pagination.pageIndex + 1}
              previousPage={table.previousPage}
              totalItems={table.getFilteredRowModel().rows.length}
            />
          )}
        </TableFrame>
      );
    },
  ),
  {
    Frame: TableFrame,
    Header: TableHeader,
    Body: TableBody,
    Row: TableRow,
    Cell: TableCell,
    Loader: TableLoadingState,
  },
);
