import { useRef } from 'react';

import { FormattedMessage, FormattedNumber } from 'react-intl';

import { TamaguiElement } from '@tamagui/core';

import { Table, TitleText, ValueText } from '@arrived/bricks';

const HYPOTHETICAL_RETURN_DATA = [1000, 5000, 10000, 50000, 100000];

export const PCF_RETURN_PERCENT = 0.081;

export const PcfHypotheticalReturnsTable = () => {
  const tableBodyScrollRef = useRef<TamaguiElement>(null);

  return (
    <Table.Frame>
      <Table.Body height={338} tableBodyScrollRef={tableBodyScrollRef}>
        <Table.Row variant="header" bg="$onSurface.primary.default">
          <Table.Cell bg="$onSurface.primary.default" borderBottomWidth={0} alignItems="center">
            <TitleText token="title.heading.small" color="$onSurface.neutral.defaultInverted">
              <FormattedMessage id="landing.start.pcf.initialInvestment" />
            </TitleText>
          </Table.Cell>
          <Table.Cell bg="$onSurface.primary.default" borderBottomWidth={0} alignItems="center">
            <TitleText token="title.heading.small" color="$onSurface.neutral.defaultInverted">
              <FormattedMessage
                id="landing.start.pcf.annualDividend"
                values={{ percentage: PCF_RETURN_PERCENT * 100 }}
              />
            </TitleText>
          </Table.Cell>
        </Table.Row>
        {HYPOTHETICAL_RETURN_DATA.map((amount) => {
          return (
            <Table.Row key={`pcf-return-${amount}`}>
              <Table.Cell pl="$10">
                <ValueText token="value.large" color="$onSurface.neutral.muted">
                  <FormattedNumber value={amount} style="currency" currency="USD" maximumFractionDigits={0} />
                </ValueText>
              </Table.Cell>
              <Table.Cell isNumericalData={true} pr="$10">
                <ValueText token="value.large" color="$onSurface.primary.decorative">
                  <FormattedNumber
                    value={amount * PCF_RETURN_PERCENT}
                    style="currency"
                    currency="USD"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </ValueText>
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table.Frame>
  );
};
