import { GestureReponderEvent } from '@tamagui/core';

import { HeaderGroup, flexRender } from '@tanstack/react-table';

import { TableCell } from './TableCell';
import { TableRow } from './TableRow';

export const TableHeader = <D,>({
  headerGroups,
  sortEnabled: sortEnabledForTable = true,
}: {
  headerGroups: HeaderGroup<D>[];
  sortEnabled?: boolean;
  lastPinnedColumnId?: string;
}) => {
  return (
    <>
      {headerGroups.map((headerGroup: HeaderGroup<D>, headerGroupIdx: number) => (
        <TableRow variant="header" key={headerGroupIdx}>
          {headerGroup.headers.map((header, columnIdx) => {
            const isSorted = header.column.getIsSorted();
            const sortEnabledForColumn = header.column.getCanSort() && sortEnabledForTable;
            const handleToggleSort = header.column.getToggleSortingHandler();
            const isPinnedColumn = header.column.getIsPinned() === 'left';

            if (header.isPlaceholder) {
              return (
                <TableCell
                  key={`${headerGroupIdx}-col-${columnIdx}`}
                  width={header.getSize()}
                  left={isPinnedColumn ? header.column.getStart() : 0}
                  isFirstColumn={columnIdx === 0}
                  isLastColumn={columnIdx + 1 === headerGroup.headers.length}
                  isPinnedColumn={isPinnedColumn}
                />
              );
            } else {
              return (
                <TableCell.Header
                  key={`${headerGroupIdx}-col-${columnIdx}`}
                  width={header.getSize()}
                  left={isPinnedColumn ? header.column.getStart() : 0}
                  sortEnabled={sortEnabledForColumn}
                  sortDirection={isSorted}
                  onPress={(e: GestureReponderEvent) => {
                    if (sortEnabledForColumn && handleToggleSort) {
                      handleToggleSort(e);
                    }
                  }}
                  isFirstColumn={columnIdx === 0}
                  isLastColumn={columnIdx + 1 === headerGroup.headers.length}
                  isPinnedColumn={isPinnedColumn}
                  {...header.column.columnDef.meta}
                  aria-colindex={columnIdx + 1}
                  aria-rowindex={1}
                  tabIndex={0}
                  aria-sort={sortEnabledForColumn && isSorted === 'asc' ? 'ascending' : 'descending'}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableCell.Header>
              );
            }
          })}
        </TableRow>
      ))}
    </>
  );
};
