import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function SortAscendingInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 6.88h5V5.63h-5v1.25zm17.525 2.525l-.884.884-2.266-2.266v6.357h-1.25V8.023l-2.266 2.266-.884-.884L15 5.63l3.775 3.775zM8.75 10.63h-7.5V9.38h7.5v1.25zm-7.5 2.5v1.25h10v-1.25h-10z"
        fill={color}
      />
    </Svg>
  );
}

export const SortAscendingIcon = memo<IconProps>(withIconTheme(SortAscendingInternal));
