import { memo } from 'react';

import { Path, Svg } from 'react-native-svg';

import { IconProps } from '../IconProps';
import { withIconTheme } from '../withIconTheme';

function SortDescendingInternal({ size, color, ...rest }: IconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...rest}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 5.625h-10v1.25h10v-1.25zm-.025 4.975l.884-.884 2.266 2.266V5.625h1.25v6.357l2.266-2.266.884.884L15 14.375 11.225 10.6zM6.25 13.125h-5v1.25h5v-1.25zm-5-3.75h7.5v1.25h-7.5v-1.25z"
        fill={color}
      />
    </Svg>
  );
}

export const SortDescendingIcon = memo<IconProps>(withIconTheme(SortDescendingInternal));
